import React from 'react'
// import { BanditBotOffCanvas } from './BanditBotOffCanvas'
import { BanditBotOffCanvasIRS } from './BanditBotOffCanvas2'
export const BanditBot = () => {
  return (
    <>
      <BanditBotOffCanvasIRS />
      {/* <BanditBotOffCanvasTBS/> */}

    </>
  )
}
