import React from "react";

export default function UserMessage({ text }) {
  return (
    <div className="message-container d-flex align-items-center">

      <div className="user-message">{text}</div>
      <i className="mdi mdi-account rounded-circle px-2 py-1 lh-sm fs-5 text-white bg-dark-blue "></i>
    </div>
  );
}
