import axios from 'axios';
import { generateSignature } from './common/authenticate';

const API = {
  GetChatbotResponse: async (message, uuid) => {

    if (message === "start") {
      setTimeout(() => {
      }, 2000)
      return {
        bot_response:process.env.REACT_APP_TBS_IRS_WELCOME_MESSAGE,
        sourceDocuments : []}
    } else {
      try {
        const { hashedKey, timestamp } = generateSignature()
        const req = {
          "body": {
            "uuid": uuid,
            "query": message,
            "signature": hashedKey,
            "timestamp": timestamp?.toString()
          }
        }
        console.log("API Url--->",process.env.REACT_APP_API_URLL)
        const response = await axios.post(`${process.env.REACT_APP_API_URL}`,
          req
        );
        
        if (response?.data?.statusCode === 200) {
          const res = JSON.parse(response?.data?.body);
          const sourceDocuments = res?.source_documents || [];
          console.log ("sourceDocuments",sourceDocuments)
          return {
            bot_response:res?.bot_response ,
            sourceDocuments : sourceDocuments};
        }

        return 'Try with a different question!'
      } catch (e) {
        return `Please try after some time!`
      }
    }
  },
  GetChatbotIRSResponse: async (message, uuid) => {

    if (message === "start") {
      setTimeout(() => {
      }, 2000)
      return {
        bot_response:'Welcome to TaxBandits! Our Bandit Bot (Beta Version) is here to help you navigate through the intricacies of tax compliance. Ask a question to get started!',
        sourceDocuments : []}
    } else {
      try {
        const { hashedKey, timestamp } = generateSignature()
        const req = {
          "body": {
            "uuid": uuid,
            "query": message,
            "signature": hashedKey,
            "timestamp": timestamp?.toString()
          }
        }

        const response = await axios.post(`${process.env.REACT_APP_API_IRS_URL}`,
          req
        );
        
        if (response?.data?.statusCode === 200) {
          const res = JSON.parse(response?.data?.body);
          const sourceDocuments = res?.source_documents || [];
          console.log ("sourceDocuments",sourceDocuments)
          return {
            bot_response:res?.bot_response ,
            sourceDocuments : sourceDocuments};
        }

        return 'Try with a different question!'
      } catch (e) {
        return `Please try after some time!`
      }
    }
  }
};

export default API;