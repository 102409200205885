import CryptoJS from 'crypto-js';

const computeHash = (clientSecret, message) => {
    const hmac = CryptoJS.HmacSHA256(message, clientSecret)
    const hmacBase64 = CryptoJS.enc.Base64.stringify(hmac);
    return hmacBase64;
}

export const generateSignature = () =>{
    const timestamp  = Math.floor(new Date().getTime() / 1000) 
    const clientId = '87f6067fa8250069' 
    const secretKey = '1be8e696-7314-4dd3-b55a-57013779d747'
    const message = clientId + '\n' + timestamp;
    
    // Compare this Signature with the Webhook Header Signature
    const hashedKey = computeHash(secretKey, message)
    return {
        hashedKey,
        timestamp
    } 
}