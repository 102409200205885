import React from "react";

const Loader = () => {
  return (
    <div id="wave">
      <span className="text" style={{ color: '#4c95f1', fontSize: '0.9em' }}>Thinking </span>
      <span className="dot" style={{ backgroundColor: '#4c95f1' }}></span>
      <span className="dot" style={{ backgroundColor: '#4c95f1' }}></span>
      <span className="dot" style={{ backgroundColor: '#4c95f1' }}></span>
    </div>
  );
}

export default Loader;